import { render, staticRenderFns } from "./RegisterAKC.vue?vue&type=template&id=442b7286&scoped=true&"
import script from "./RegisterAKC.vue?vue&type=script&lang=ts&"
export * from "./RegisterAKC.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterAKC.vue?vue&type=style&index=0&id=442b7286&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442b7286",
  null
  
)

export default component.exports