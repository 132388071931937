








































import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("registrationCompleted")}`,
      meta: [
        {
          name: "description",
          content: "Registration Completed Message",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class RegistrationCompleted extends Vue {}
