import { render, staticRenderFns } from "./RegisterSourceSeedProducer.vue?vue&type=template&id=a45507de&scoped=true&"
import script from "./RegisterSourceSeedProducer.vue?vue&type=script&lang=ts&"
export * from "./RegisterSourceSeedProducer.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterSourceSeedProducer.vue?vue&type=style&index=0&id=a45507de&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a45507de",
  null
  
)

export default component.exports