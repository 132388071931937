var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Registrationinit myCard"},[_c('h3',{staticClass:"bolder heading textCenter"},[_vm._v(_vm._s(_vm.$t("registerDOADText")))]),_c('div',{staticClass:"flex flexColumn alignCenter",attrs:{"element-loading-text":"Registering ..."}},[_c('div',{staticClass:"registration fullWidth"},[_c('el-form',{staticClass:"custom-form",attrs:{"label-position":_vm.labelPosition,"label-width":"100px","model":_vm.orgdetails}},[_c('div',{staticClass:"basic_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("basicInfo")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('name')),
                  'is-required': _vm.checkIfRequired(_vm.$t('name'))
                },attrs:{"label":_vm.$t('name')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('name')},model:{value:(_vm.user.user.first_name),callback:function ($$v) {_vm.$set(_vm.user.user, "first_name", $$v)},expression:"user.user.first_name"}}),(_vm.vErrors.has(_vm.$t('name')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("name")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
                  'is-error': _vm.vErrors.has(_vm.$t('email')),
                  'is-required': _vm.checkIfRequired(_vm.$t('email'))
                },attrs:{"label":_vm.$t('email')}},[_c('el-input',{attrs:{"name":_vm.$t('email'),"v-validate":!_vm.$route.params.id ? 'required|email|checkUserExists' : 'required|email'},model:{value:(_vm.user.user.email),callback:function ($$v) {_vm.$set(_vm.user.user, "email", $$v)},expression:"user.user.email"}}),(_vm.vErrors.has(_vm.$t('email')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("email")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"basic_info col-lg-12 col-md-12 col-sm-12"},[_c('h5',[_vm._v(_vm._s(_vm.$t("assignedPlace")))]),_vm._l((_vm.user.userinroles),function(userinrole,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-12"},[_c('el-form-item',{class:{
                      'is-error': _vm.vErrors.has(((_vm.$t('province')) + " " + (index + 1))),
                      'is-required': _vm.checkIfRequired(((_vm.$t('province')) + " " + (index + 1)))
                    },attrs:{"label":_vm.$t('province')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":((_vm.$t('province')) + " " + (index + 1)),"filterable":""},model:{value:(userinrole.province),callback:function ($$v) {_vm.$set(userinrole, "province", $$v)},expression:"userinrole.province"}},_vm._l((_vm.provinces),function(province){return _c('el-option',{key:province.id,attrs:{"value":province.id,"label":_vm.$i18n.locale == 'ne' && province.nepname
                            ? province.nepname
                            : province.name}})}),1),(_vm.vErrors.has(((_vm.$t('province')) + " " + (index + 1))))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(((_vm.$t("province")) + " " + (index + 1))))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-4 col-sm-12"},[_c('el-form-item',{attrs:{"label":" "}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addUserInRole}},[_vm._v(_vm._s(_vm.$t("add")))]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.removeUserInRole(index)}}},[_vm._v(_vm._s(_vm.$t("remove")))])],1)],1)])})],2)])])])],1),_c('div',[_c('el-button',{staticClass:"nxt",attrs:{"type":"success"},on:{"click":_vm.addDOAD}},[_vm._v(_vm._s(_vm.nextText))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }