





















































import { Component, Vue } from "vue-property-decorator";
import CommonStep from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedConsumer/CommonStep.vue";
import ChooseIsmType from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedConsumer/ChooseISMType.vue";
// import StepTwo from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedProducer/StepTwo.vue";
import { ImprovedSeedUser } from "@/store/models/user";
import { UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    CommonStep,
    ChooseIsmType
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("consumer")} ${this.$t(
        "registration"
      )}`,
      meta: [
        {
          name: "description",
          content: "Improved Seed Producer Registration",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class extends Vue {
  active: number = 0;
  checkedTerms: Boolean = false;
  disableConfirm: Boolean = false;
  user: ImprovedSeedUser = {
    role: "improved_seed_consumer",
    isc_type: "palika"
  } as ImprovedSeedUser;
  message: string = "";

  total_steps: number = 2;

  get nextText() {
    if (this.active == this.total_steps - 1) {
      return this.$t("register");
    }
    return this.$t("next");
  }

  // user: ImprovedSeedUser = {
  //   corporation_type: "Government",
  //   name: "Aoeu",
  //   nepname: "sthtNEPALI",
  //   email: "aoeu@aoeeu.com",
  //   provinceid: 1,
  //   districtid: 1,
  //   palikaid: 1,
  //   ward: 1,
  //   contact_person: "aoeu",
  //   contact_number: "9841123123",
  //   bank_name: "1",
  //   account_number: "1923",
  //   role: "improved_seed_consumer",
  //   web_address: ""
  // } as ImprovedSeedUser;

  application: File[] = [] as File[];
  pan: File[] = [] as File[];
  seed_transaction_certificate: File[] = [] as File[];
  meeting_minute: File[] = [] as File[];
  improved_seed_application: File[] = [] as File[];
  seedConsumerEmailVerification: File[] = [] as File[];

  toggleSubmitButton() {
    let checkedTerms = this.checkedTerms;

    if (checkedTerms) {
      this.disableConfirm = false;
    } else {
      this.disableConfirm = true;
    }
  }

  back() {
    if (this.active > 0) {
      window.scrollTo(0, 0);
      this.active--;
    }
  }

  async nextstep() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.active < 0) {
        this.disableConfirm = false;
        this.active = 0;
      } else if (this.active < this.total_steps) {
        this.disableConfirm = false;
        this.active++;
        if (this.active == this.total_steps - 1) {
          this.disableConfirm = true;
        }
      }
      if (this.active >= this.total_steps) {
        this.user.username = this.user.email;
        if (this.user.isc_type == "farmer") {
          this.user.contact_person = this.user.name;
        }

        let company = new FormData();
        for (let key in this.user as any) {
          company.append(key, (this.user as any)[key]);
        }

        for (let file of this.application) {
          company.append("application", file);
        }

        for (let file of this.pan) {
          company.append("pan", file);
        }

        for (let file of this.seed_transaction_certificate) {
          company.append("seed_transaction_certificate", file);
        }

        for (let file of this.meeting_minute) {
          company.append("meeting_minute", file);
        }

        for (let file of this.improved_seed_application) {
          company.append("improved_seed_application", file);
        }

        for (let file of this.seedConsumerEmailVerification) {
          company.append("improved_seed_consumer_email_verification", file);
        }

        this.active = -1;
        let a = await UserStore.registerImprovedSeedUser(company).catch((reason: any) => {
          this.active = -2;
          this.message = this.$t("registrationFailedMessage").toString();
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        });
        this.active = -2;
        if (a.id !== undefined && a.id !== null && a.id !== 0 && a.id !== "") {
          this.message = this.$t("registrationSuccessfulMessage").toString();
          this.$notify.success({
            title: this.$t("registrationSuccessfulTitle").toString(),
            message: this.$t("registrationSuccessfulMessage").toString()
          });
          this.user = {} as ImprovedSeedUser;
          this.$router.push({ name: "RegistrationCompleted" }).catch(error => {});
        } else {
          this.message = this.$t("registrationFailedMessage").toString();
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        }
      }
    } else {
      document
        .getElementsByName(this.$validator.errors.items[0].field)[0]
        .scrollIntoView({ behavior: "smooth", block: "center" });
      this.$notify.error({
        title: this.$t("invalidDataTitle").toString(),
        message: this.$t("invalidDataMessage").toString()
      });
    }
  }

  handleUploadImprovedSeedApplication(file: any) {
    this.improved_seed_application = [];
    this.improved_seed_application.push(file);
  }

  handleAfterUploaduploadImprovedSeedConsumerEmailverification(file: any) {
    this.seedConsumerEmailVerification = [];
    this.seedConsumerEmailVerification.push(file);
  }

  handleAfterUploadApplication(file: any) {
    this.application = [];
    this.application.push(file);
  }

  handleAfterUploadPAN(file: any) {
    this.pan = [];
    this.pan.push(file);
  }

  handleAfterUploadSeedTransactionCertificate(file: any) {
    this.seed_transaction_certificate = [];
    this.seed_transaction_certificate.push(file);
  }

  handleAfterUploadMeetingMinute(file: any) {
    this.meeting_minute = [];
    this.meeting_minute.push(file);
  }
}

/**
 *
 * COMMON
 * Name
 * Address (Province, District, Palika, Ward)
 * Contact Person, Contact No
 * Email/Username
 *
 */
