
















































































































































import { Component, Vue } from "vue-property-decorator";
import CommonStep from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedConsumer/CommonStep.vue";
import ChooseIsmType from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedConsumer/ChooseISMType.vue";
// import StepTwo from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedProducer/StepTwo.vue";
import { AKCUser, BaseUser, ImprovedSeedUser, UserInRole } from "@/store/models/user";
import { MetaStore, UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import Adminmenu from "@/views/Admin/Adminmenu.vue";

@Component({
  components: {
    CommonStep,
    ChooseIsmType
  },
  metaInfo(): MetaInfo {
    return {
      title: `AKC ${this.$t("registration")}`,
      meta: [
        {
          name: "description",
          content: "Improved Seed Producer Registration",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class extends Vue {
  user: AKCUser = {
    user: {},
    userinroles: [{}] as UserInRole[]
  } as AKCUser;

  message: string = "";

  labelPosition: string = "top";
  orgdetails = {
    name: "",
    region: "",
    type: "",
    password: ""
  };

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  get nextText() {
    if (this.$route.params.id) {
      return this.$t("update");
    }
    return this.$t("register");
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    return district;
  }

  addUserInRole() {
    this.user.userinroles.push({} as UserInRole);
  }

  removeUserInRole(index: number) {
    if (this.user.userinroles.length > 1) {
      this.user.userinroles.splice(index, 1);
    }
  }

  // user: ImprovedSeedUser = {
  //   corporation_type: "Government",
  //   name: "Aoeu",
  //   nepname: "sthtNEPALI",
  //   email: "aoeu@aoeeu.com",
  //   provinceid: 1,
  //   districtid: 1,
  //   palikaid: 1,
  //   ward: 1,
  //   contact_person: "aoeu",
  //   contact_number: "9841123123",
  //   bank_name: "1",
  //   account_number: "1923",
  //   role: "improved_seed_consumer",
  //   web_address: ""
  // } as ImprovedSeedUser;

  // application: File[] = [] as File[];
  // pan: File[] = [] as File[];
  // seed_transaction_certificate: File[] = [] as File[];
  // meeting_minute: File[] = [] as File[];
  // improved_seed_application: File[] = [] as File[];

  async addAKC() {
    this.user.user.username = this.user.user.email;

    let isValid = await this.$validator.validateAll();
    let error = false;

    for (let userRole of this.user.userinroles) {
      userRole.role = 37;
      console.log("id-> ", userRole.role);
    }

    if (isValid) {
      if (this.$route.params.id) {
        await UserStore.updateAKCUser({
          id: this.$route.params.id,
          user: this.user
        }).catch(e => {
          console.log(e);
          error = true;
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        });
      } else {
        this.user.user.password = "sqcc123";
        await UserStore.registerAKCUser(this.user).catch(e => {
          console.log(e);
          error = true;
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        });
      }

      if (!error) {
        if (this.$route.params.id) {
          this.$notify.success({
            title: this.$t("updateSuccessfulTitle").toString(),
            message: ""
          });
        } else {
          this.$notify.success({
            title: this.$t("registrationSuccessfulTitle").toString(),
            message: this.$t("registrationSuccessfulMessage").toString()
          });
        }
        this.$router.push({
          name: "ViewerList"
        });
      }
    }
  }

  //   async nextstep() {
  //     let isValid = await this.$validator.validateAll();
  //     if (isValid) {
  //       if (this.active >= this.total_steps) {
  //         this.user.username = this.user.email;
  //         if (this.user.isc_type == "farmer") {
  //           this.user.contact_person = this.user.name;
  //         }

  //         let company = new FormData();
  //         for (let key in this.user as any) {
  //           company.append(key, (this.user as any)[key]);
  //         }

  //         for (let file of this.application) {
  //           company.append("application", file);
  //         }

  //         for (let file of this.pan) {
  //           company.append("pan", file);
  //         }

  //         for (let file of this.seed_transaction_certificate) {
  //           company.append("seed_transaction_certificate", file);
  //         }

  //         for (let file of this.meeting_minute) {
  //           company.append("meeting_minute", file);
  //         }

  //         for (let file of this.improved_seed_application) {
  //           company.append("improved_seed_application", file);
  //         }

  //         let a = await UserStore.registerImprovedSeedUser(company).catch((reason: any) => {
  //           this.message = this.$t("registrationFailedMessage").toString();
  //           this.$notify.error({
  //             title: this.$t("registrationFailedTitle").toString(),
  //             message: this.$t("registrationFailedMessage").toString()
  //           });
  //         });
  //         if (a.id !== undefined && a.id !== null && a.id !== 0 && a.id !== "") {
  //           this.message = this.$t("registrationSuccessfulMessage").toString();
  //           this.$notify.success({
  //             title: this.$t("registrationSuccessfulTitle").toString(),
  //             message: this.$t("registrationSuccessfulMessage").toString()
  //           });
  //           this.user = {} as ImprovedSeedUser;
  //           this.$router.push({ name: "RegistrationCompleted" }).catch(error => {});
  //         } else {
  //           this.message = this.$t("registrationFailedMessage").toString();
  //           this.$notify.error({
  //             title: this.$t("registrationFailedTitle").toString(),
  //             message: this.$t("registrationFailedMessage").toString()
  //           });
  //         }
  //       }
  //     } else {
  //       document
  //         .getElementsByName(this.$validator.errors.items[0].field)[0]
  //         .scrollIntoView({ behavior: "smooth", block: "center" });
  //       this.$notify.error({
  //         title: this.$t("invalidDataTitle").toString(),
  //         message: this.$t("invalidDataMessage").toString()
  //       });
  //     }
  //   }

  async created() {
    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();

    if (this.$route.params) {
      const id = (this as any).$route.params.id;
      await UserStore.getAKCUserById({
        id: id
      });
      this.user.user = UserStore.AKCUser.user;
      this.user.userinroles = UserStore.AKCUser.userinroles;
      console.log("usr->", this.user);
      console.log("akc -> ", UserStore.AKCUser);
    }
  }
}

/**
 *
 * COMMON
 * Name
 * Address (Province, District, Palika, Ward)
 * Contact Person, Contact No
 * Email/Username
 *
 */
