

















































import { Component, Vue } from "vue-property-decorator";
import CommonStep from "@/views/Authscreen/Subsidy/Steps/CommonStep.vue";
import StepTwo from "@/views/Authscreen/Subsidy/Steps/ImprovedSeedProducer/StepTwo.vue";
import { ImprovedSeedProducer } from "@/store/models/user";
import { UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    CommonStep,
    StepTwo
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("producer")} ${this.$t(
        "registration"
      )}`,
      meta: [
        {
          name: "description",
          content: "Improved Seed Producer Registration",
          vmid: "registration"
        }
      ]
    };
  }
})
export default class extends Vue {
  checkedTerms: Boolean = false;
  disableConfirm: Boolean = true;
  active: number = 0;
  user: ImprovedSeedProducer = {
    role: "improved_seed_producer"
  } as ImprovedSeedProducer;
  message: string = "";

  total_steps: number = 1;

  get nextText() {
    if (this.active == this.total_steps - 1) {
      return this.$t("register");
    }
    return this.$t("next");
  }

  // user: ImprovedSeedProducer = {
  //   corporation_type: "Government",
  //   name: "Aoeu",
  //   nepname: "sthtNEPALI",
  //   email: "aoeu@aoeeu.com",
  //   provinceid: 1,
  //   districtid: 1,
  //   palikaid: 1,
  //   ward: 1,
  //   contact_person: "aoeu",
  //   contact_number: "9841123123",
  //   bank_name: "11",
  //   account_number: "1923",
  //   role: "improved_seed_producer",
  //   web_address: ""
  // } as ImprovedSeedProducer;

  seedCompanyLicense: File[] = [] as File[];
  trainingApplication: File[] = [] as File[]
  companyRegistrationRenewalCertificate: File[] = [] as File[];
  tax: File[] = [] as File[];
  purchaseProof: File[] = [] as File[];
  pan: File[] = [] as File[];
  chequeBookCover: File[] = [] as File[];
  otherDocument: File[] = [] as File[];
  districtApproval: File[] = [] as File[];

    toggleSubmitButton() {
    let checkedTerms = this.checkedTerms;

    if (checkedTerms) {
      this.disableConfirm = false;
    } else {
      this.disableConfirm = true;
    }
  }

  back() {
    if (this.active > 0) {
      this.active--;
    }
  }

  async nextstep() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      if (this.active < 0) {
        this.active = 0;
      } else if (this.active < this.total_steps) {
        this.active++;
      }
      if (this.active >= this.total_steps) {
        this.user.username = this.user.email;

        let company = new FormData();
        for (let key in this.user as any) {
          company.append(key, (this.user as any)[key]);
        }

        for (let file of this.seedCompanyLicense) {
          company.append("company_license", file);
        }

        for (let file of this.trainingApplication){
          company.append("training_application", file)
        }

        for (let file of this.companyRegistrationRenewalCertificate) {
          company.append("company_registration_renewal_certificate", file);
        }

        for (let file of this.tax) {
          company.append("tax", file);
        }

        for (let file of this.pan) {
          company.append("pan", file);
        }

        for (let file of this.purchaseProof) {
          company.append("purchase_proof", file);
        }

        for (let file of this.chequeBookCover) {
          company.append("cheque_book_cover", file);
        }

        for (let file of this.otherDocument) {
          company.append("otherdocument", file);
        }

        for (let file of this.districtApproval) {
          company.append("district_approval", file);
        }

        this.active = -1;
        let a = await UserStore.registerImprovedSeedProducer(company).catch((reason: any) => {
          this.active = -2;
          this.message = this.$t("registrationFailedMessage").toString();
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        });
        this.active = -2;
        if (a.id !== undefined && a.id !== null && a.id !== 0 && a.id !== "") {
          this.message = this.$t("registrationSuccessfulMessage").toString();
          this.$notify.success({
            title: this.$t("registrationSuccessfulTitle").toString(),
            message: this.$t("registrationSuccessfulMessage").toString()
          });
          this.user = {} as ImprovedSeedProducer;
          this.$router.push({ name: "RegistrationCompleted" }).catch(error => {});
        } else {
          this.message = this.$t("registrationFailedMessage").toString();
          this.$notify.error({
            title: this.$t("registrationFailedTitle").toString(),
            message: this.$t("registrationFailedMessage").toString()
          });
        }
      }
    } else {
      document
        .getElementsByName(this.$validator.errors.items[0].field)[0]
        .scrollIntoView({ behavior: "smooth", block: "center" });
      this.$notify.error({
        title: this.$t("invalidDataTitle").toString(),
        message: this.$t("invalidDataMessage").toString()
      });
    }
  }

  handleAfterUploadPAN(file: any) {
    this.pan = [];
    this.pan.push(file);
  }

  handleAfterUploadSeedCompanyLicense(file: any) {
    this.seedCompanyLicense = [];
    this.seedCompanyLicense.push(file);
  }

  handleAfterUploadTrainingApplication(file: any) {
    this.trainingApplication = [];
    this.trainingApplication.push(file);
  }

  handleAfterUploadCompanyRegistrationRenewalCertificate(file: any) {
    this.companyRegistrationRenewalCertificate = [];
    this.companyRegistrationRenewalCertificate.push(file);
  }

  handleAfterUploadTax(file: any) {
    this.tax = [];
    this.tax.push(file);
  }

  handleAfterUploadParentSeedPurchaseProof(file: any) {
    this.purchaseProof = [];
    this.purchaseProof.push(file);
  }

  

  handleAfterUploadOtherDocument(file: any) {
    this.otherDocument = [];
    this.otherDocument.push(file);
  }

  handleAfterUploadDistrictApproval(file: any) {
    this.districtApproval = [];
    this.districtApproval.push(file);
  }
}

/**
 *
 * COMMON
 * Name
 * Address (Province, District, Palika, Ward)
 * Contact Person, Contact No
 * Email/Username
 *
 */
